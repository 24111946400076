import { getMoneyToNumber } from "@/utils/stringUtils";

export default class DraftPurchaseGoodsListModel {
  constructor(obj) {
    this.objRaw = {};

    this.objItemData = {};

    this.amount = ""; // 수량
    this.categoryId = ""; // 품목코드
    this.categoryName = ""; // 품명
    this.goodsName = ""; // 품명
    this.madeIn = ""; // 입고처
    this.note = ""; // 비고
    this.price = ""; // 금액
    this.unitPrice = ""; // 단가
    this.unitType = ""; // 단위코드(EA, Copy, 식, 직접입력)
    this.unitValue = ""; // 단위값(직접입력 단위값)
    this.assetInfoNum = ""; // 자산정보 아이디
    this.warranty = ""; // 보증기간
    this.assetStatus = ""; // 자산상태

    if (obj) {
      this.setData(obj);
    }
  }

  /**
   * @deprecated 사용안함.
   * @param obj
   */
  setItemData(obj) {
    this.objItemData = obj;

    const {
      amount,
      categoryId,
      categoryName,
      madeIn,
      note,
      price,
      unitPrice,
      unitType,
      unitValue,
      assetInfoNum,
    } = obj;

    this.amount = amount || amount === 0 ? String(amount) : "";
    this.categoryId = categoryId || "";
    this.categoryName = categoryName || "";
    this.madeIn = madeIn || "";
    this.note = note || "";
    this.price = price || price === 0 ? String(price) : "";
    this.unitPrice = unitPrice || unitPrice === 0 ? String(unitPrice) : "";
    this.unitType = unitType || "";
    this.unitValue = unitValue || "";
    this.assetInfoNum = assetInfoNum;
  }

  setData(obj) {
    this.objRaw = obj;

    const {
      amount,
      categoryId,
      categoryName,
      goodsName,
      madeIn,
      note,
      price,
      unitPrice,
      unitType,
      unitValue,
      assetInfoNum,
    } = obj;

    this.amount = amount || amount === 0 ? String(amount) : "";
    this.categoryId = categoryId || "";
    this.categoryName = goodsName || categoryName || "";
    this.goodsName = goodsName;
    this.madeIn = madeIn || "";
    this.note = note || "";
    this.price = price || price === 0 ? String(price) : "";
    this.unitPrice = unitPrice || unitPrice === 0 ? String(unitPrice) : "";
    this.unitType = unitType || "";
    this.unitValue = unitValue || "";
  }

  changeCalcPrice() {
    const { amount, unitPrice } = this;

    if (!amount && !unitPrice) return "0";

    const cal = getMoneyToNumber(amount) * getMoneyToNumber(unitPrice);

    this.price = String(cal);
  }

  getData() {
    let obj = {
      amount: getMoneyToNumber(this.amount),
      categoryId: this.categoryId,
      categoryName: this.categoryName,
      goodsName: this.categoryName,
      madeIn: this.madeIn,
      note: this.note,
      price: getMoneyToNumber(this.price),
      unitPrice: getMoneyToNumber(this.unitPrice),
      unitType: this.unitType,
      unitValue: this.unitValue,
    };

    return Object.assign({}, this.objItemData, this.objRaw, obj);
  }

  getAlertMessage() {
    if (this.amount === 0 || this.amount === "0" || !this.amount)
      return "품목의 수량을 확인해주세요.";
    if (this.unitPrice !== 0 && !this.unitPrice) return "품목의 단가를 확인해주세요.";

    return null;
  }
}
